/* src/assets/fonts/fonts.css */

@font-face {
     font-family: 'Amatic SC';
     src: url('./AmaticSC-Regular.woff2') format('woff2'),
          url('./AmaticSC-Regular.woff') format('woff');
     font-weight: 400;
     font-style: normal;
     font-display: swap;
   }
   
   @font-face {
     font-family: 'Amatic SC';
     src: url('./AmaticSC-Bold.woff2') format('woff2'),
          url('./AmaticSC-Bold.woff') format('woff');
     font-weight: 700;
     font-style: normal;
     font-display: swap;
   }
   
   @font-face {
     font-family: 'Finger Paint';
     src: url('./FingerPaint-Regular.woff2') format('woff2'),
          url('./FingerPaint-Regular.woff') format('woff');
     font-weight: 400;
     font-style: normal;
     font-display: swap;
   }
   
   @font-face {
     font-family: 'Bebas Neue';
     src: url('./BebasNeue-Regular.woff2') format('woff2'),
          url('./BebasNeue-Regular.woff') format('woff');
     font-weight: 400;
     font-style: normal;
     font-display: swap;
   }
   
   @font-face {
     font-family: 'Montserrat';
     src: url('./Montserrat-Regular.woff2') format('woff2'),
          url('./Montserrat-Regular.woff') format('woff');
     font-weight: 400;
     font-style: normal;
     font-display: swap;
   }
   
   @font-face {
     font-family: 'Montserrat';
     src: url('./Montserrat-Bold.woff2') format('woff2'),
          url('./Montserrat-Bold.woff') format('woff');
     font-weight: 700;
     font-style: normal;
     font-display: swap;
   }
   
   @font-face {
     font-family: 'Montserrat';
     src: url('./Montserrat-ExtraBold.woff2') format('woff2'),
          url('./Montserrat-ExtraBold.woff') format('woff');
     font-weight: 800;
     font-style: normal;
     font-display: swap;
   }
   
   @font-face {
     font-family: 'Roboto Mono';
     src: url('./RobotoMono-Regular.woff2') format('woff2'),
          url('./RobotoMono-Regular.woff') format('woff');
     font-weight: 400;
     font-style: normal;
     font-display: swap;
   }
   
   @font-face {
     font-family: 'Roboto Mono';
     src: url('./RobotoMono-Bold.woff2') format('woff2'),
          url('./RobotoMono-Bold.woff') format('woff');
     font-weight: 700;
     font-style: normal;
     font-display: swap;
   }
   